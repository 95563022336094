<template>
	<div class="BestPeopleDetail">
		<w-navTab titleText="今日义警排行"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="content">
			<div class="body">
				<div class="body-content">
					<div class="body-top">
						<div class="body-top-left">
							<img :src="detail.avatar" alt="">
							<div class="left-img-sort">{{total}}</div>
						</div>
						<div class="body-top-right">
							<div class="body-right-name">{{detail.name}}</div>
							<div class="body-right-text">{{detail.district_txt}} {{detail.level_text}}</div>
							<div class="body-right-number">{{detail.total_love_value}}爱心值</div>
						</div>
					</div>
					<div class="body-list">
						<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
							<div class="body-list-item" v-for="item in bestLoveList">
								<div class="body-list-title">{{item.description}}</div>
								<div class="body-list-number">+{{item.love_value}}爱心值</div>
							</div>
						</VantList>
					</div>
					<div class="body-button" @click="isShowPicker = !isShowPicker" v-if="district_id != 2">
						<div class="body-button-icon">
							<img src="../../assets/img/icon612.png" alt="">
						</div>
						<div class="body-button-text">作弊举报</div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="isShowPicker">
			<div class="showPopup">
				<div class="showPopup-box">
					<div class="showPopup-title">作弊举报</div>
					<div class="showPopup-textarea">
						<textarea v-model="content" placeholder="请输入举报原因"></textarea>
					</div>
				</div>
				<div class="showPopup-footer">
					<div class="showPopup-footer-left" @click.stop="isShowPicker = false">取消</div>
					<div class="showPopup-footer-right" @click.stop="onSubmit">提交</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import LoadingPage from '@/components/LoadingPage';
	import Best from '@/api/best';
	import VantVendor from '@/vendor/vant';
	import VantList from '@/components/VantList';

	export default {
		name: 'BestPeopleDetail',
		data() {
			return {
				loadingPage: true,
				isShowPicker: false,
				content: '',
				userId: '',
				bestLoveList: [],
				page: 1,
				limit: 10,
				loading: true,
				finished: false,
				isEmpty: false,
				detail: '',
				district_id: ''
			}
		},
		created() {
			this.userId = this.$route.params.id
			this.total = this.$route.params.total
			this.district_id = this.$route.params.district_id
			this.getDetail()
			this.onLoad()
		},
		methods: {
			getDetail(){
				let params = {
					district_id: this.district_id,
					user_id: this.userId
				}
				Best.bestDetail(params)
					.then(result => {
						this.detail = result.data
					}, error => {
						console.log(error)
					})
			},
			/**
			 * 义警积分明细
			 */
			onLoad() {
				this.loading = true;
				
				let params = {
					district_id: this.district_id,
					user_id: this.userId,
					page: this.page,
					limit: this.limit
				}
				
				Best.bestLoveValue(params)
					.then(result => {
						this.page++;
						this.bestLoveList = this.bestLoveList.concat(result.data);
						
						this.loading = false;
						
						if (this.bestLoveList.length <= 0) {
							this.isEmpty = true;
						}
						
						if (result.data.length < this.limit) {
							this.finished = true;
						}
						
						this.loadingPage = false
					}, error => {
						console.log(error)
					})
			},
			
			// 提交举报
			onSubmit() {
				VantVendor.Toast.loading({
				  message: '提交中...',
				  forbidClick: true,
				});
				
				let params = {
					user_id: this.userId,
					content: this.content
				}
				Best.bestReport(params)
					.then(result => {
						this.content = ''
						this.isShowPicker = false
						VantVendor.Toast.success("提交成功")
					}, error => {
						VantVendor.Toast(error.msg)
					})
			}
		},
		components: {
			VantList,
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.BestPeopleDetail {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;
		padding-bottom: 20px;
		box-sizing: border-box;

		.content {
			.body {
				.body-content {
					padding: 28px 16px 0;
					box-sizing: border-box;
					position: relative;

					.body-top {
						border-radius: 8px;
						background: #FFF;
						display: flex;
						justify-content: space-between;
						align-items: center;
						box-sizing: border-box;

						&.body-top:last-child {
							margin-bottom: 0;
						}

						.body-top-left {
							margin-right: 12px;
							width: 80px;
							height: 80px;
							position: relative;

							img {
								width: 100%;
								height: 100%;
								border-radius: 4px;
								object-fit: cover;
							}

							.left-img-sort {
								position: absolute;
								top: 0;
								left: 0;
								width: 24px;
								height: 24px;
								border-radius: 4px 0px;
								background: #FF6969;
								color: #FFF;
								text-align: center;
								font-size: 14px;
								line-height: 24px;
							}
						}

						.body-top-right {
							flex: 1;

							.body-right-name {
								color: #222;
								font-size: 18px;
								line-height: 27px;
							}

							.body-right-text {
								margin: 4px 0 7px;
								color: #777;
								font-size: 14px;
								line-height: 21px;
							}

							.body-right-number {
								color: #FF6969;
								font-size: 14px;
								line-height: 21px;
							}

						}
					}

					.body-list {
						margin-top: 16px;

						.body-list-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 16px 0;
							border-bottom: 1px solid rgba(34, 34, 34, 0.10);
							box-sizing: border-box;

							.body-list-title {
								color: #222;
								font-size: 16px;
								line-height: 24px;
							}

							.body-list-number {
								color: #37F;
								font-size: 16px;
								line-height: 24px;
							}
						}
					}

					.body-button {
						position: absolute;
						top: 24px;
						right: 16px;
						width: 84px;
						padding: 5px 8px;
						border-radius: 13px;
						background: #F6F6F6;
						box-sizing: border-box;
						display: flex;
						align-items: center;

						.body-button-icon {
							width: 16px;
							height: 16px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}

						.body-button-text {
							color: #999;
							font-size: 13px;
							line-height: 16px;
						}
					}
				}
			}
		}

		.showPopup {
			background: #FFF;
			border-radius: 6px;
			overflow: hidden;

			.showPopup-box {
				padding: 15px;

				.showPopup-title {
					font-weight: bold;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #222;
				}

				.showPopup-textarea {
					margin-top: 9px;

					textarea {
						width: 266px;
						height: 107px;
						background: #F2F2F2;
						border: 0;
						resize: none;
						padding-left: 5px;
					}
				}
			}

			.showPopup-footer {
				display: flex;
				align-items: center;
				border-top: 1px solid #DDD;
				box-sizing: border-box;

				.showPopup-footer-left {
					flex: 1;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #AAA;
					padding: 8px 0;
					border-right: 1px solid #DDD;
					box-sizing: border-box;
				}

				.showPopup-footer-right {
					flex: 1;
					font-size: 17px;
					line-height: 28px;
					text-align: center;
					color: #37F;
					padding: 8px 0;
					box-sizing: border-box;
				}
			}
		}
	}
</style>